import { homepageMediaBreakpointUp } from '@/components/pages/home/shared/utilities/layout';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

const ComparisonSection = styled.section`
  padding-top: ${remCalc(80)};

  ${homepageMediaBreakpointUp('tabletMedium')} {
    padding-top: ${remCalc(100)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    padding-top: ${remCalc(120)};
  }
`;

const ComparisonSectionHeadline = styled.h2`
  ${customTextSize(24, 30)};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tabletMedium')} {
    ${customTextSize(32, 38)};
    margin-bottom: ${remCalc(40)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(49, 48)};
    margin-bottom: ${remCalc(60)};
  }
`;

const ComparisonSectionDesktopItemsGrid = styled.div`
  display: none;

  ${homepageMediaBreakpointUp('desktop')} {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    column-gap: ${remCalc(20)};
    > * {
      grid-column: span 3;
    }
  }
`;

const ComparisonSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${remCalc(40)};

  ${homepageMediaBreakpointUp('desktopWide')} {
    row-gap: ${remCalc(60)};
  }
`;

const ComparisonSectionItemHeadline = styled.h3`
  ${customTextSize(16, 26)};
  font-weight: ${FONT_WEIGHTS.BOLD};
  text-align: center;
  margin-bottom: ${remCalc(8)};

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(24, 34)};
    margin-bottom: ${remCalc(12)};
  }
`;

const ComparisonSectionItemStatement = styled.p`
  ${customTextSize(16, 24)};
  text-align: center;

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(20, 26)};
    margin-bottom: ${remCalc(12)};
  }
`;

const ComparisonSectionItemImageWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ComparisonSectionMobileSummary = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tablet')} {
    grid-template-columns: repeat(9, 1fr);
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-template-columns: repeat(18, 1fr);
  }

  ${homepageMediaBreakpointUp('desktop')} {
    display: none;
  }
`;

const ComparisonSectionMobileSummaryImageWrapper = styled.div`
  grid-column: 1/-1;
  margin-bottom: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-column: 3/-3;
    margin-bottom: ${remCalc(40)};
  }
`;

const ComparisonSectionMobileSummaryHeadline = styled.div`
  grid-column: 1/-1;
  margin-bottom: ${remCalc(8)};
  ${customTextSize(16, 21)};
  font-weight: ${FONT_WEIGHTS.BOLD};
  text-align: center;

  ${homepageMediaBreakpointUp('tablet')} {
    margin-bottom: ${remCalc(10)};
  }
`;

const ComparisonSectionMobileSummaryStatement = styled.div`
  grid-column: 1/-1;
  ${customTextSize(14, 20)};
  text-align: center;

  ${homepageMediaBreakpointUp('mobileMedium')} {
    ${customTextSize(16, 24)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2/-2;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-column: 4/-4;
  }
`;

const homepageComparisonComponents = {
  ComparisonSection,
  ComparisonSectionDesktopItemsGrid,
  ComparisonSectionHeadline,
  ComparisonSectionItem,
  ComparisonSectionItemHeadline,
  ComparisonSectionItemImageWrapper,
  ComparisonSectionItemStatement,
  ComparisonSectionMobileSummary,
  ComparisonSectionMobileSummaryHeadline,
  ComparisonSectionMobileSummaryImageWrapper,
  ComparisonSectionMobileSummaryStatement,
};

export default homepageComparisonComponents;
