import Link from '@/components/Link';
import { COLORS } from '@/styles/color';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface SocialProofCardProps extends PropsWithChildren {
  cta?: {
    text: string;
    href: string;
    newTab?: boolean;
    onClick?: () => void;
  };
}

const SocialProofCard: FC<SocialProofCardProps & PropsWithClassName> = ({ className, ...props }) => (
  <Card className={className}>
    {props.children}

    {props.cta ? (
      <Link href={props.cta.href}>
        <CardCTA
          target={props.cta.newTab ? '_blank' : '_self'}
          onClick={() => {
            if (props.cta!.onClick) {
              props.cta!.onClick();
            }
          }}
        >
          {props.cta.text} →
        </CardCTA>
      </Link>
    ) : null}
  </Card>
);

export default SocialProofCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${remCalc(0, 0, 30, 0)} rgba(0, 27, 56, 0.1);
  border-radius: ${remCalc(12)};
  padding: ${remCalc(30, 25)};
  transition: all 0.3s ease-in-out;
  background-color: ${COLORS.WHITE};
  min-height: var(--social-proof-carousel-card-min-height, none);

  :hover {
    transform: scale(1.1);
  }

  ${mediaBreakpointUp('tablet')} {
    padding: ${remCalc(50, 50, 38, 50)};
  }
`;

const CardCTA = styled.a`
  ${customTextSize(16, 24)};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  align-self: center;
  margin: 1rem 0 0 0;
`;
