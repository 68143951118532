import { BREAKPOINTS, BREAKPOINT_PADDING } from '@/styles/layout';
import { remCalc } from '@/styles/typography/utils';
import { useEffect, useState } from 'react';

const useHomepageBreakpoint = (): [HomepageLayoutBreakpoint | undefined, HomepageLayoutBreakpoint[]] => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<HomepageLayoutBreakpoint>();
  const [activeBreakpoints, setActiveBreakpoints] = useState<HomepageLayoutBreakpoint[]>([]);

  useEffect(() => {
    const handleResize = () => {
      const currentBreakpoints = Object.values(HOMEPAGE_BREAKPOINTS)
        .filter((px) => px <= window.innerWidth)
        .map((px) => {
          const index = Object.values(HOMEPAGE_BREAKPOINTS).findIndex((bpx) => bpx === px);

          return Object.keys(HOMEPAGE_BREAKPOINTS)[index] as HomepageLayoutBreakpoint;
        });

      const newCurrentBreakpoint = currentBreakpoints![currentBreakpoints.length! - 1] as HomepageLayoutBreakpoint;

      if (currentBreakpoint !== newCurrentBreakpoint) {
        setCurrentBreakpoint(newCurrentBreakpoint);
      }

      if (currentBreakpoints.length !== activeBreakpoints.length) {
        setActiveBreakpoints(currentBreakpoints);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [currentBreakpoint, activeBreakpoints]);

  return [currentBreakpoint, activeBreakpoints];
};

export default useHomepageBreakpoint;

export const homepageMediaBreakpointUp = (b: HomepageLayoutBreakpoint) =>
  `@media (min-width: ${remCalc(HOMEPAGE_BREAKPOINTS[b])})`;

export const homepageMediaBreakpointDown = (b: HomepageLayoutBreakpoint) => {
  let pixels = 0;

  const index = Object.keys(HOMEPAGE_BREAKPOINTS).findIndex((breakpoint) => breakpoint === b);
  const nextBreakpoint = Object.values(HOMEPAGE_BREAKPOINTS)[index + 1];

  if (nextBreakpoint) {
    pixels = nextBreakpoint - 1;
  }

  return `@media (max-width: ${remCalc(pixels)})`;
};

export const homepageMediaBreakpointBetween = (b1: HomepageLayoutBreakpoint, b2: HomepageLayoutBreakpoint) => {
  const sorted = [HOMEPAGE_BREAKPOINTS[b1], HOMEPAGE_BREAKPOINTS[b2]].sort((a, b) => a - b);

  return `@media (min-width: ${remCalc(sorted[0])}) and (max-width: ${remCalc(sorted[1])})`;
};

export const homepageMediaBreakpointOnly = (b: HomepageLayoutBreakpoint) => {
  let min = 0;
  let max = 0;

  const index = Object.keys(HOMEPAGE_BREAKPOINTS).findIndex((breakpoint) => breakpoint === b);

  if (index === 0) {
    max = Object.values(HOMEPAGE_BREAKPOINTS)[1] - 1;
  } else {
    min = Object.values(HOMEPAGE_BREAKPOINTS)[index];

    const nextBreakpoint = Object.values(HOMEPAGE_BREAKPOINTS)[index + 1];

    if (nextBreakpoint) {
      max = nextBreakpoint - 1;
    }
  }

  if (max > 0) {
    return `@media (min-width: ${remCalc(min)}) and (max-width: ${remCalc(max)})`;
  }

  return `@media (min-width: ${remCalc(HOMEPAGE_BREAKPOINTS[b])})`;
};

/**
 * Homepage Breakpoints
 */

export const HOMEPAGE_BREAKPOINTS = {
  ...BREAKPOINTS,
  mobileMedium: 375,
  tabletMedium: 768,
} as const;

export type HomepageLayoutBreakpoint = keyof typeof HOMEPAGE_BREAKPOINTS;

/**
 * Homepage Breakpoint Padding
 * Defines how much padding is on each side of the container
 */
export const HOMEPAGE_BREAKPOINT_PADDING = {
  ...BREAKPOINT_PADDING,
  mobileMedium: 32,
  tabletMedium: 48,
} as const;
