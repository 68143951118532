import { ReactComponent as PlayButtonSVG } from '@/assets/images/svg/buttons/play-button.svg';
import { PrimaryDefaultButton } from '@/components/buttons/primary';
import {
  HOMEPAGE_BREAKPOINT_PADDING,
  homepageMediaBreakpointUp,
} from '@/components/pages/home/shared/utilities/layout';
import { COLORS, GRADIENTS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { square } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

const ProductOverviewSection = styled.section`
  background: transparent;
  color: ${COLORS.BLACK};
  margin-left: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};
  margin-right: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};
  padding-top: ${remCalc(10)};
  padding-bottom: ${remCalc(40)};

  ${homepageMediaBreakpointUp('mobileMedium')} {
    margin-left: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
    margin-right: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    border-radius: ${remCalc(12)};
    padding-top: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    margin-left: -${remCalc(24)};
    margin-right: -${remCalc(24)};
    padding-bottom: ${remCalc(60)};
    border-radius: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    margin-left: -${remCalc(45)};
    margin-right: -${remCalc(45)};
    padding-top: ${remCalc(30)};
    padding-bottom: ${remCalc(80)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    padding-top: ${remCalc(40)};
    padding-bottom: ${remCalc(120)};
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(30)};
  width: 100%;
  padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};
  padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};

  ${homepageMediaBreakpointUp('mobileMedium')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-template-columns: repeat(16, 1fr);
    row-gap: ${remCalc(40)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(18, 1fr);
    row-gap: ${remCalc(60)};
  }
`;

const Headline = styled.h3`
  ${customTextSize(24, 30)};
  letter-spacing: -0.01em;
  text-align: center;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  white-space: pre-line;
  margin-top: ${remCalc(40)};

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2/-2;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    ${customTextSize(32, 38)};
    margin-top: ${remCalc(60)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-column: 3/-3;
    padding: 0 ${remCalc(32)};
    margin-top: ${remCalc(80)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(50, 58)};
    padding: 0;
    margin-top: ${remCalc(120)};
  }
`;

const HeroVideoContainer = styled.div`
  margin-left: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};
  margin-right: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};

  ${homepageMediaBreakpointUp('mobileMedium')} {
    margin-left: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
    margin-right: -${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 1/-1;
    margin: unset;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    padding: 0 ${remCalc(35)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-column: 2/-2;
    padding: 0 ${remCalc(12)};
  }
`;

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${hexToRGBA(COLORS.BLACK, 0.15)};
`;

const PlayButton = styled(PlayButtonSVG)`
  position: absolute;
  top: calc(50% - ${remCalc(140 / 2)});
  left: calc(50% - ${remCalc(140 / 2)});
  width: ${remCalc(140)};
  z-index: 2;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  box-shadow: 0 0 30px 10px ${hexToRGBA(COLORS.BLACK, 0.25)};
  border-radius: 100%;
  pointer-events: none;
  background-color: ${COLORS.WHITE};
`;

const HeroVideoWrapper = styled.div`
  --gradient-transparency: 0.6;
  position: relative;
  aspect-ratio: 16 / 9;

  border-radius: ${remCalc(10)};
  overflow: hidden;
`;

const HeroVideoWrapperTest = styled(HeroVideoWrapper)`
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    --gradient-transparency: 0.2;
    transform: scale(1.025);
  }
`;

const FeatureGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: ${remCalc(20)};
  column-gap: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2/-2;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    row-gap: ${remCalc(30)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    column-gap: ${remCalc(20)};
  }
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: ${remCalc(20)};
  width: calc(50% - ${remCalc(10)});

  ${homepageMediaBreakpointUp('tabletMedium')} {
    width: calc(33.333% - ${remCalc(40 / 3)});
  }

  ${homepageMediaBreakpointUp('desktop')} {
    width: calc(25% - ${remCalc(17)});
  }
`;

const FeatureIconWrapper = styled.div<{ iconSrc: string }>`
  ${square(40)}

  ${homepageMediaBreakpointUp('tablet')} {
    ${square(60)}
  }

  mask: url(${({ iconSrc }) => iconSrc}) no-repeat center;
  mask-size: contain;
  background-color: ${COLORS.BLACK};

  > img {
    opacity: 0;
  }
`;

const FeatureName = styled.span`
  text-align: center;
  ${customTextSize(16, 21)};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};

  ${homepageMediaBreakpointUp('desktop')} {
    ${customTextSize(20, 24)};
  }
`;

const FeatureGridCTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  > a {
    ${customTextSize(16, 24)};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};

    :hover {
      cursor: pointer;
    }
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2/-2;
    flex-direction: row;
  }
`;

const TourOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${remCalc(3)};
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    background: ${GRADIENTS.DARK};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: var(--gradient-transparency);
    transition: opacity 0.2s ease-in-out;
  }

  ${PrimaryDefaultButton} {
    position: relative;
    --button-color: ${COLORS.WHITE};
    --button-color-hover: ${COLORS.WHITE};
    --button-text-color: ${COLORS.BLACK};
    --button-text-color-hover: ${COLORS.BLACK};

    box-shadow: 0px 0px 20px 0 ${hexToRGBA(COLORS.BLACK, 0.3)};

    :hover {
      box-shadow: 0px 0px 20px 0 ${hexToRGBA(COLORS.BLACK, 0.5)};
    }
  }
`;

const homepageProductOverviewComponents = {
  FeatureGrid,
  FeatureGridCTAWrapper,
  FeatureIconWrapper,
  FeatureItem,
  FeatureName,
  Headline,
  HeroVideoContainer,
  HeroVideoWrapper,
  HeroVideoWrapperTest,
  ProductOverviewSection,
  SectionContainer,
  PlayButtonWrapper,
  PlayButton,
  TourOverlay,
};

export default homepageProductOverviewComponents;
