import reactStringReplace from 'react-string-replace';

const applyStrongTag = (text: string | null | undefined) => {
  return reactStringReplace(text ?? '', /\*(.+?)\*/gi, (match, i) => <strong key={i}>{match}</strong>);
};

const applyEmTag = (text: string | null | undefined) => {
  return reactStringReplace(text ?? '', /\_(.+?)\_/gi, (match, i) => <em key={i}>{match}</em>);
};

export { applyStrongTag, applyEmTag };
