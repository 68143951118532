import FrontPinkButton from '@/components/buttons/primary/FrontPinkButton';
import SecondaryDefaultButton from '@/components/buttons/secondary';
import { DEMO_CTA_TEXT, TRIAL_CTA_TEXT } from '@/constants/strings';
import { GlobalContext } from '@/context';
import gtm from '@/lib/gtm';
import { FC, useContext } from 'react';
import { HomePageContainer } from '../../shared/containers/container';
import { applyStrongTag } from '../../shared/utilities/textUtils';
import Styled from './styled.components';

export interface HomePageHeroProps {
  headline: string;
  statement: string;
}

const HomePageHero: FC<HomePageHeroProps> = ({ headline, statement }): JSX.Element => {
  const { toggleDemoModal, toggleTrialModal } = useContext(GlobalContext);

  return (
    <HomePageContainer>
      <Styled.HeroSection>
        <Styled.Headline>{applyStrongTag(headline)}</Styled.Headline>
        <Styled.Statement>{statement}</Styled.Statement>

        <Styled.CTAWrapper>
          <FrontPinkButton
            type="button"
            onClick={() => {
              toggleTrialModal(true);

              gtm.track('click', {
                element_type: 'button',
                location: 'Hero',
                element_label: TRIAL_CTA_TEXT,
              });
            }}
          >
            {TRIAL_CTA_TEXT}
          </FrontPinkButton>

          <SecondaryDefaultButton
            as="button"
            onClick={() => {
              toggleDemoModal(true);

              gtm.track('click', {
                element_type: 'button',
                location: 'Hero',
                element_label: DEMO_CTA_TEXT,
              });
            }}
          >
            {DEMO_CTA_TEXT}
          </SecondaryDefaultButton>
        </Styled.CTAWrapper>
      </Styled.HeroSection>
    </HomePageContainer>
  );
};

export default HomePageHero;
