import { homepageMediaBreakpointUp } from '@/components/pages/home/shared/utilities/layout';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${remCalc(20)};
  margin-bottom: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tablet')} {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    column-gap: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-template-columns: repeat(16, 1fr);
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(18, 1fr);
    row-gap: ${remCalc(30)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    row-gap: ${remCalc(40)};
  }
`;

const Headline = styled.h1`
  ${customTextSize(32, 38)};
  text-align: center;
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  letter-spacing: -0.01em;

  strong {
    background: linear-gradient(90deg, #ff2b73 19.92%, #ff6c35 79.79%), transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  }

  @media (max-width: ${remCalc(386)}) {
    white-space: pre-line;
  }

  ${homepageMediaBreakpointUp('mobileMedium')} {
    ${customTextSize(38, 46)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 1/-1;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-column: 2/-2;
    ${customTextSize(49, 58)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    ${customTextSize(60, 66)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(80, 88)};
    grid-column: 2/-2;
  }
`;

const Statement = styled.p`
  ${customTextSize(14, 20)};
  text-align: center;
  font-weight: ${FONT_WEIGHTS.NORMAL};

  ${homepageMediaBreakpointUp('mobileMedium')} {
    ${customTextSize(16, 24)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2/-2;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-column: 3/-3;
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-column: 2/-2;
    ${customTextSize(24, 34)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    grid-column: 4/-4;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  grid-column: 1/-1;

  ${mediaBreakpointUp('tablet')} {
    flex-direction: row;
  }
`;

const homepageHeroComponents = { HeroSection, Headline, Statement, CTAWrapper };

export default homepageHeroComponents;
