import { homepageMediaBreakpointUp } from '@/components/pages/home/shared/utilities/layout';
import { remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

const PageWrapper = styled.div`
  padding-top: ${remCalc(90)};

  ${homepageMediaBreakpointUp('tablet')} {
    padding-top: ${remCalc(100)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    padding-top: ${remCalc(110)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    padding-top: ${remCalc(120)};
  }
`;

const PageContainer = styled.div`
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
  }
`;

const GradientSectionOne = styled.section`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55.94%, #ffffff 100%),
    radial-gradient(50% 65.18% at 100% 100%, #fffadd 0%, rgba(255, 252, 236, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(63.5% 72.5% at 0% 100%, #acf8e2 0%, rgba(220, 255, 243, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(85.51% 107.61% at 116.48% -16.77%, #b0f1fc 0%, rgba(217, 248, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(85.41% 95.63% at -15.24% -17.56%, #ffd4e6 0%, rgba(255, 212, 230, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #ffffff;
`;

const GradientSectionTwo = styled.section`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%),
    radial-gradient(50% 65.18% at 100% 100%, #fffadd 0%, rgba(255, 252, 236, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(63.5% 72.5% at 0% 100%, #acf8e2 0%, rgba(220, 255, 243, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(85.51% 107.61% at 116.48% -16.77%, #b0f1fc 0%, rgba(217, 248, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(85.41% 95.63% at -15.24% -17.56%, #ffd4e6 0%, rgba(255, 212, 230, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #ffffff;
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const TransformResetContainer = styled.div`
  transform: scaleY(-1);
`;

const homepageComponents = {
  PageWrapper,
  PageContainer,
  GradientSectionOne,
  GradientSectionTwo,
  TransformResetContainer,
};

export default homepageComponents;
