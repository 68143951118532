import Image from '@/components/Image';
import { FC } from 'react';
import { HomePageContainer } from '../../shared/containers/container';
import Styled from './styled.components';
import { applyEmTag } from '@/components/pages/home/shared/utilities/textUtils';

export interface ComparisonSectionProps {
  headline: string;
  mobileSummary: MobileSummary;
  desktopItems: Item[];
}

interface Item {
  title: string;
  statement: string;
  image: Image;
}

interface MobileSummary {
  statement: string;
  mobileSubHeadline: string;
  image: Image;
}

interface Image {
  src: string;
  alt: string;
  height: number;
  width: number;
}

const ComparisonSection: FC<ComparisonSectionProps> = ({ headline, desktopItems, mobileSummary }): JSX.Element => {
  const { statement: mobileStatement, mobileSubHeadline: mobileHeadline, image: mobileImage } = mobileSummary;

  return (
    <HomePageContainer>
      <Styled.ComparisonSection>
        <Styled.ComparisonSectionHeadline>{headline}</Styled.ComparisonSectionHeadline>
        {desktopItems ? (
          <Styled.ComparisonSectionDesktopItemsGrid>
            {desktopItems.map(
              ({ image, title, statement }, index): JSX.Element => (
                <Styled.ComparisonSectionItem key={`comparison-section-item-${index}`}>
                  <Styled.ComparisonSectionItemImageWrapper>
                    <Image {...image} />
                  </Styled.ComparisonSectionItemImageWrapper>
                  <div>
                    <Styled.ComparisonSectionItemHeadline>{applyEmTag(title)}</Styled.ComparisonSectionItemHeadline>
                    <Styled.ComparisonSectionItemStatement>{statement}</Styled.ComparisonSectionItemStatement>
                  </div>
                </Styled.ComparisonSectionItem>
              ),
            )}
          </Styled.ComparisonSectionDesktopItemsGrid>
        ) : null}
        {mobileSummary ? (
          <Styled.ComparisonSectionMobileSummary>
            <Styled.ComparisonSectionMobileSummaryImageWrapper>
              <Image {...mobileImage} />
            </Styled.ComparisonSectionMobileSummaryImageWrapper>
            <Styled.ComparisonSectionMobileSummaryHeadline>
              {applyEmTag(mobileHeadline)}
            </Styled.ComparisonSectionMobileSummaryHeadline>
            <Styled.ComparisonSectionMobileSummaryStatement>
              {mobileStatement}
            </Styled.ComparisonSectionMobileSummaryStatement>
          </Styled.ComparisonSectionMobileSummary>
        ) : null}
      </Styled.ComparisonSection>
    </HomePageContainer>
  );
};

export default ComparisonSection;
