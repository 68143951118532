import Link from '@/components/Link';
import { VectorSegmentProps } from '@/components/pages/home/sections/vectors/index';
import Styled from '@/components/pages/home/sections/vectors/styled.components';
import { applyStrongTag } from '@/components/pages/home/shared/utilities/textUtils';
import gtm from '@/lib/gtm';
import { FC, RefObject, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const Slide: FC<VectorSegmentProps & { rootRef: RefObject<HTMLDivElement> }> = ({
  headline,
  statement,
  video,
  cta,
  rootRef,
}) => {
  const {
    inView,
    ref: videoRef,
    entry,
  } = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
    root: rootRef?.current,
  });

  useEffect((): (() => void) | undefined => {
    if (!entry) return;

    const video = entry.target as HTMLVideoElement;

    if (video.tagName.toUpperCase() !== 'VIDEO') return;

    const onScroll = () => {
      if (inView) {
        video.play().catch(() => {
          // Commonly a NotAllowedError, which we can just silently ignore
        });
      } else {
        try {
          video.pause();
        } catch {}
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [inView, entry]);

  return (
    <Styled.Slide>
      <Styled.VideoWrapper>
        {video ? (
          <video
            muted
            loop
            ref={videoRef}
            src={video.src}
            width={video.width}
            height={video.height}
            playsInline
            autoPlay
          />
        ) : null}
      </Styled.VideoWrapper>

      <Styled.VectorsSegmentTextContent>
        <Styled.SlideHeadline>{applyStrongTag(headline)}</Styled.SlideHeadline>
        <Styled.SlideStatement>{statement}</Styled.SlideStatement>
        <Styled.SlideStatement>
          <Link href={cta.href}>
            <a
              onClick={() => {
                {
                  gtm.track('click', {
                    element_label: cta.text,
                    element_type: 'link',
                    location: 'Capabilities Section',
                  });
                }
              }}
            >
              {cta.text} &rarr;
            </a>
          </Link>
        </Styled.SlideStatement>
      </Styled.VectorsSegmentTextContent>
    </Styled.Slide>
  );
};

export default Slide;
