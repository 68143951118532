import { SocialProofCardProps } from '@/components/_atoms/social-proof-cards/SocialProofCard';
import SocialProofStatCard from '@/components/_atoms/social-proof-cards/SocialProofStatCard';
import GradientFooter from '@/components/_organisms/GradientFooter';
import LogoCarousel, { LogoWrapper } from '@/components/_organisms/LogoCarousel';
import SocialProofCardCarousel from '@/components/_organisms/SocialProofCardCarousel';
import FrontPinkButton from '@/components/buttons/primary/FrontPinkButton';
import SecondaryDefaultButton from '@/components/buttons/secondary';
import Image from '@/components/Image';
import { Container } from '@/components/layout/Container';
import ProductOverviewFeatures from '@/components/pages/home/sections/product-overview/features';
import { HomePageContainer } from '@/components/pages/home/shared/containers/container';
import { homepageMediaBreakpointUp } from '@/components/pages/home/shared/utilities/layout';
import { CUSTOMER_STORIES_HOME } from '@/constants/routes';
import { DEMO_CTA_TEXT, TRIAL_CTA_TEXT } from '@/constants/strings';
import { GlobalContext } from '@/context';
import gtm from '@/lib/gtm';
import { parseHtml } from '@/lib/parse-html';
import { addElementIdentifier } from '@/lib/utils';
import { mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { remCalc } from '@/styles/typography/utils';
import { useContext } from 'react';
import styled from 'styled-components';
import ComparisonSection, { ComparisonSectionProps } from './sections/comparison';
import HomePageHero, { HomePageHeroProps } from './sections/hero';
import ProductOverview, { ProductOverviewSectionProps } from './sections/product-overview';
import VectorsSection, { VectorsSectionProps } from './sections/vectors';
import Styled from './styled.components';

export interface HomePageProps {
  entry: {
    hero: HomePageHeroProps;
    logoFarm: any;
    productOverview: ProductOverviewSectionProps;
    vectors: VectorsSectionProps;
    comparison: ComparisonSectionProps;
    socialProof: any;
    ctaFooter: any;
  };
}

const Home: PageTemplate<HomePageProps> = ({ entry }) => {
  const { toggleDemoModal, toggleTrialModal } = useContext(GlobalContext);

  return (
    <Styled.PageContainer>
      <Styled.GradientSectionOne>
        <Styled.PageWrapper>
          <HomePageHero {...entry.hero} />
        </Styled.PageWrapper>

        <ProductOverview {...entry.productOverview} />
      </Styled.GradientSectionOne>

      <Container>
        <LogoCarousel
          logos={entry.logoFarm.logos.map((logo) => (
            <LogoWrapper>
              <Image src={logo.src} width={logo.width} height={logo.height} alt={`${logo.customer} logo`} />
            </LogoWrapper>
          ))}
        />
      </Container>

      <HomePageContainer>
        <ProductOverviewFeatures {...entry.productOverview} />
      </HomePageContainer>

      <VectorsSection {...entry.vectors} />

      <StyledSocialProofCardCarousel
        heading={entry.socialProof.headline}
        cta={{
          href: CUSTOMER_STORIES_HOME,
          text: 'See how leading service teams use Front →',
        }}
      >
        {entry.socialProof.cards.map((card) => {
          const cta: SocialProofCardProps['cta'] = card.link
            ? {
                text: 'Learn more',
                href: card.link,
                newTab: true,
                onClick() {
                  gtm.track('click', {
                    location: 'Social Proof Section',
                    element_type: 'link',
                    element_label: 'Quote Card',
                    card_link: card.link,
                  });
                },
              }
            : undefined;

          return (
            <SocialProofStatCard
              key={card.key}
              value={card.stat?.value!}
              label={card.stat?.label!}
              logo={card.logo ? <Image {...card.logo} /> : null}
              cta={cta}
            >
              {parseHtml(card.content)}
            </SocialProofStatCard>
          );
        })}
      </StyledSocialProofCardCarousel>

      <ComparisonSection {...entry.comparison} />

      <StyledGradientFooter
        heading={entry.ctaFooter.headline}
        cta={
          <>
            <FrontPinkButton
              type="button"
              onClick={() => {
                toggleTrialModal(true);

                gtm.track('click', {
                  location: 'Reviews Section',
                  element_type: 'button',
                  element_label: TRIAL_CTA_TEXT,
                });
              }}
              {...addElementIdentifier('footer-cta-trial')}
            >
              {TRIAL_CTA_TEXT}
            </FrontPinkButton>

            <SecondaryDefaultButton
              as="button"
              onClick={() => {
                toggleDemoModal(true);

                gtm.track('click', {
                  location: 'Reviews Section',
                  element_type: 'button',
                  element_label: DEMO_CTA_TEXT,
                });
              }}
              {...addElementIdentifier('footer-cta-demo')}
            >
              {DEMO_CTA_TEXT}
            </SecondaryDefaultButton>
          </>
        }
      />
    </Styled.PageContainer>
  );
};

export default Home;

const StyledSocialProofCardCarousel = styled(SocialProofCardCarousel)`
  padding-top: ${remCalc(80)};

  ${homepageMediaBreakpointUp('tabletMedium')} {
    padding-top: ${remCalc(100)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    padding-top: ${remCalc(120)};
  }
`;

const StyledGradientFooter = styled(GradientFooter)`
  ${paddingY(40)}

  ${mediaBreakpointUp('tablet')} {
    ${paddingY(50)}
  }

  ${mediaBreakpointUp('desktop')} {
    ${paddingY(100)}
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${paddingY(120)}
  }
`;
