import ComparisonData from '../components/comparison.json';
import CTAFooterData from '../components/cta-footer.json';
import HeroData from '../components/hero.json';
import LogoFarmData from '../components/logo-farm.json';
import ProductOverViewData from '../components/product-overview.json';
import SocialProofData from '../components/social-proof.json';
import VectorsData from '../components/vectors.json';

const HomePageData = {
  entry: {
    comparison: ComparisonData,
    ctaFooter: CTAFooterData,
    hero: HeroData,
    logoFarm: LogoFarmData,
    productOverview: ProductOverViewData,
    socialProof: SocialProofData,
    vectors: VectorsData,
  },
};

export default HomePageData;
