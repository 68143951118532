import HomepageTemplate from '@/components/pages/home';
import entryProps from '@/components/pages/home/data/entry';
import { getStaticPageProps } from '@/lib/next';
import MasterTemplate from '@/templates/MasterTemplate';

const Homepage: PageTemplate = () => {
  return <HomepageTemplate {...entryProps} />;
};

Homepage.template = MasterTemplate;

export default Homepage;

export const getStaticProps = getStaticPageProps('__home__');
