import { Container } from '@/components/layout/Container';
import {
  homepageMediaBreakpointUp,
  HOMEPAGE_BREAKPOINT_PADDING,
} from '@/components/pages/home/shared/utilities/layout';
import { remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';

export const HomePageContainer = styled(Container)`
  ${homepageMediaBreakpointUp('mobile')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobile)};
  }

  ${homepageMediaBreakpointUp('mobileMedium')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.mobileMedium)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.tablet)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.tablet)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.tabletMedium)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.tabletMedium)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.desktop)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.desktop)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    padding-left: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.desktopWide)};
    padding-right: ${remCalc(HOMEPAGE_BREAKPOINT_PADDING.desktopWide)};
  }
`;
