import { PrimaryDefaultButton } from '@/components/buttons/primary';
import ProductTourModal from '@/components/modals/ProductTourModal';
import gtm from '@/lib/gtm';
import useProductTourDecision from '@/lib/hooks/useProductTourDecision';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { HomePageContainer } from '../../shared/containers/container';
import Styled from './styled.components';

interface FeatureItem {
  name: string;
  icon: {
    src: string;
  };
  href: string;
}

export interface ProductOverviewSectionProps {
  headline: string;
  features: FeatureItem[];
  heroVideo: {
    src: string;
    poster: string;
  };
}

const ProductOverview: FC<ProductOverviewSectionProps> = ({ heroVideo }): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showTour, setShowTour] = useState(false);
  const isTourEligible = useProductTourDecision();
  const { ref, inView } = useInView({
    threshold: 0.9,
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
  });

  let HeroVideoWrapperComponent = Styled.HeroVideoWrapper;
  let { poster, src } = heroVideo;

  if (isTourEligible) {
    HeroVideoWrapperComponent = Styled.HeroVideoWrapperTest;
    poster = '/staticwebassets/pages/homepage/product-video-trimmed-poster-v2.jpg';
    src = '/staticwebassets/pages/homepage/product-video-trimmed-v2.mp4';
  }

  useEffect(() => {
    if (isTourEligible) return;

    if (inView && videoRef.current) {
      const video = videoRef.current;
      const isVideoPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

      if (!isVideoPlaying) {
        video.play().catch((e) => {
          // Commonly a NotAllowedError, which we can just silently ignore
        });
      }
    }
  }, [inView, videoRef.current, isTourEligible]);

  const onVideoButtonClick = () => {
    setShowTour(true);

    gtm.track('click', {
      element_type: 'button',
      element_label: 'Take a tour',
      location: 'Product Overview Section',
    });
  };

  return (
    <HomePageContainer>
      <Styled.ProductOverviewSection>
        <Styled.SectionContainer>
          <Styled.HeroVideoContainer>
            <HeroVideoWrapperComponent
              ref={ref}
              onClick={() => {
                if (isTourEligible) {
                  onVideoButtonClick();
                }
              }}
            >
              <video
                poster={poster}
                src={src}
                ref={videoRef}
                autoPlay={isTourEligible}
                loop
                muted
                controls={false}
                playsInline
                onClick={(e) => {
                  e.preventDefault();
                }}
                width={1920}
                height={1080}
              />

              {isTourEligible && (
                <Styled.TourOverlay>
                  <PrimaryDefaultButton onClick={onVideoButtonClick}>Take a tour &rarr;</PrimaryDefaultButton>
                </Styled.TourOverlay>
              )}
            </HeroVideoWrapperComponent>
          </Styled.HeroVideoContainer>
        </Styled.SectionContainer>
      </Styled.ProductOverviewSection>

      {isTourEligible && showTour && (
        <ProductTourModal
          onClose={() => {
            setShowTour(false);
          }}
        />
      )}
    </HomePageContainer>
  );
};

export default ProductOverview;
