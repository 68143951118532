import { homepageMediaBreakpointUp } from '@/components/pages/home/shared/utilities/layout';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import styled from 'styled-components';
import { HomePageContainer } from '../../shared/containers/container';

const SlidesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${remCalc(80)};
`;

const Slide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tablet')} {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto;
    column-gap: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-template-columns: repeat(12, 1fr);
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(18, 1fr);
    grid-column-gap: ${remCalc(20)};
  }
`;

const VideoWrapper = styled.div`
  > video {
    mask-image: radial-gradient(white, black);
    backface-visibility: hidden;
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2 / -2;
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-column: 1 / span 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const SlideHeadline = styled.h3`
  ${customTextSize(18, 22)};
  font-weight: ${FONT_WEIGHTS.BOLD};
  text-align: center;

  strong {
    background: linear-gradient(#ff2b73, #ff6c35);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    ${customTextSize(24, 30)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    text-align: left;
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(32, 38)};
  }
`;

const SlideStatement = styled.p`
  ${customTextSize(13, 19)};
  text-align: center;

  ${homepageMediaBreakpointUp('mobileMedium')} {
    ${customTextSize(14, 20)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    ${customTextSize(18, 26)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    ${customTextSize(16, 24)};
    text-align: left;
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(20, 26)};
  }

  a {
    ${customTextSize(16, 24)};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  }
`;

const VectorsSegmentTextContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(16)};
  grid-row: 2;

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 3 / -3;
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-row: 1;
    grid-column: 11 / span 7;
    justify-content: center;
  }
`;

const VectorsSectionContainer = styled(HomePageContainer)`
  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tablet')} {
    row-gap: ${remCalc(40)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    row-gap: ${remCalc(80)};
  }
`;

const VectorsSection = styled.div`
  padding-top: ${remCalc(40)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: ${remCalc(20)};

  ${homepageMediaBreakpointUp('tablet')} {
    padding-top: ${remCalc(60)};
    row-gap: ${remCalc(40)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    padding-top: ${remCalc(80)};
    row-gap: ${remCalc(80)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    padding-top: ${remCalc(120)};
  }
`;

const VectorsHeader = styled.header`
  ${homepageMediaBreakpointUp('tablet')} {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: auto;
    column-gap: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-template-columns: repeat(12, 1fr);
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(18, 1fr);
  }
`;

const VectorsHeadline = styled.h2`
  ${customTextSize(24, 30)};
  font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  letter-spacing: -0.01em;
  text-align: center;
  margin-bottom: ${remCalc(16)};

  strong {
    background: linear-gradient(#ff2b73, #ff6c35);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 1/-1;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    ${customTextSize(32, 38)};
    margin-bottom: ${remCalc(20)};
  }

  ${homepageMediaBreakpointUp('desktop')} {
    margin-bottom: ${remCalc(40)};
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(49, 48)};
  }
`;

const VectorsStatement = styled.p`
  ${customTextSize(14, 20)};
  text-align: center;

  ${homepageMediaBreakpointUp('mobileMedium')} {
    ${customTextSize(16, 24)};
  }

  ${homepageMediaBreakpointUp('tablet')} {
    grid-column: 2/-2;
  }

  ${homepageMediaBreakpointUp('tabletMedium')} {
    grid-column: 3/-3;
  }

  ${homepageMediaBreakpointUp('desktop')} {
    grid-column: 4/-4;
  }

  ${homepageMediaBreakpointUp('desktopWide')} {
    ${customTextSize(24, 34)};
  }
`;

const homepageVectorsComponents = {
  Slide,
  SlideHeadline,
  SlideStatement,
  SlidesContainer,
  VectorsHeader,
  VectorsHeadline,
  VectorsSection,
  VectorsSectionContainer,
  VectorsSegmentTextContent,
  VectorsStatement,
  VideoWrapper,
};

export default homepageVectorsComponents;
