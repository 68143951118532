import { PrimaryDefaultButton } from '@/components/buttons/primary';
import { COLORS } from '@/styles/color';
import styled from 'styled-components';
import { hexToRGBA } from '@/styles/color/utils';

interface FrontPinkButtonProps {
  inverted?: boolean;
}

const FrontPinkButton = styled(PrimaryDefaultButton)<FrontPinkButtonProps>`
  --button-color: ${({ inverted }) => (inverted ? COLORS.WHITE : COLORS.RED.LOWLIGHT)};
  --button-text-color: ${({ inverted }) => (inverted ? COLORS.BLACK : COLORS.WHITE)};

  box-shadow: 0px 0px 20px ${hexToRGBA(COLORS.RED.LOWLIGHT, 0)};

  :hover {
    box-shadow: 0px 0px 20px ${hexToRGBA(COLORS.RED.LOWLIGHT, 0.6)};
  }
`;

export default FrontPinkButton;
