import { Container } from '@/components/layout/Container';
import Link from '@/components/Link';
import Heading from '@/components/_atoms/Heading';
import { marginY, mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components';

export interface SocialProofCardCarouselProps extends PropsWithClassName, PropsWithChildren {
  heading?: string;
  cta?: {
    href: string;
    text: string;
    onClick?: () => void;
  };
}

const SocialProofCardCarousel: FC<SocialProofCardCarouselProps> = (props) => {
  return (
    <Wrapper className={props.className}>
      {!!props.heading ? (
        <Container>
          <StyledHeading level={2}>{props.heading}</StyledHeading>
        </Container>
      ) : null}

      <Carousel>
        <Slide>{props.children}</Slide>
        <Slide>{props.children}</Slide>
        <Slide>{props.children}</Slide>
      </Carousel>

      {props.cta ? (
        <Container>
          <CTAWrapper>
            <Link href={props.cta.href}>
              <a onClick={props.cta.onClick}>{props.cta.text}</a>
            </Link>
          </CTAWrapper>
        </Container>
      ) : null}
    </Wrapper>
  );
};

export default SocialProofCardCarousel;

// Styled Components

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: ${remCalc(50)};

  ${mediaBreakpointUp('desktop')} {
    row-gap: ${remCalc(60)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    row-gap: ${remCalc(80)};
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  position: relative;
  z-index: 2;
`;

const scrollKeyframes = keyframes`
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
`;

const Slide = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  column-gap: var(--social-proof-carousel-gap);
  flex: 0 0 auto;

  :not(:first-of-type) {
    display: none; // hiding duplicate carousel on mobile
  }

  > * {
    width: ${remCalc(260)};

    :first-child {
      margin-left: var(--social-proof-carousel-gap);
    }

    :last-child {
      margin-right: var(--social-proof-carousel-gap);
    }
  }

  ${mediaBreakpointUp('tablet')} {
    :not(:first-of-type) {
      display: flex; // showing duplicate carousel on tablet and up
    }

    > * {
      width: ${remCalc(387)};

      :first-child {
        margin-left: 0;
      }
    }

    animation: ${scrollKeyframes} 65s linear infinite;
    animation-play-state: running;
  }
`;

const Carousel = styled.div`
  --social-proof-carousel-gap: ${remCalc(30)};

  ${paddingY(50)};
  ${marginY(-50)}

  position: relative;
  z-index: 1;
  display: flex;
  overflow-x: scroll; // overflow-x: scroll allows scrolling of carousel on mobile
  width: 100%;

  ${mediaBreakpointUp('tablet')} {
    --social-proof-carousel-gap: ${remCalc(50)};

    ${paddingY(40)}
    ${marginY(-40)}
    overflow-x: hidden;

    &:hover,
    &:focus-within {
      ${Slide} {
        animation-play-state: paused;
      }
    }
  }

  ${mediaBreakpointUp('desktop')} {
    ${paddingY(60)}
    ${marginY(-60)}
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${paddingY(80)}
    ${marginY(-80)}
  }
`;

const CTAWrapper = styled.div`
  text-align: center;
  position: relative;
  z-index: 2;

  a {
    ${customTextSize(16, 24)};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};

    :hover {
      cursor: pointer;
    }
  }
`;
