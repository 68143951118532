import FrontPinkButton from '@/components/buttons/primary/FrontPinkButton';
import SecondaryDefaultButton from '@/components/buttons/secondary';
import Image from '@/components/Image';
import Link from '@/components/Link';
import { ProductOverviewSectionProps } from '@/components/pages/home/sections/product-overview/index';
import Styled from '@/components/pages/home/sections/product-overview/styled.components';
import { DEMO_CTA_TEXT, TRIAL_CTA_TEXT } from '@/constants/strings';
import { GlobalContext } from '@/context';
import gtm from '@/lib/gtm';
import React, { FC, useContext } from 'react';

const ProductOverviewFeatures: FC<ProductOverviewSectionProps> = ({ headline, features }) => {
  const { toggleDemoModal, toggleTrialModal } = useContext(GlobalContext);

  return (
    <Styled.SectionContainer>
      <Styled.Headline>{headline}</Styled.Headline>

      <Styled.FeatureGrid>
        {features.map(({ name: featureName, icon: featureIcon, href }): JSX.Element => {
          return (
            <Styled.FeatureItem key={featureName}>
              <Styled.FeatureIconWrapper iconSrc={featureIcon.src}>
                <Link
                  href={href}
                  onClick={() => {
                    gtm.track('click', {
                      element_type: 'link',
                      element_label: `${featureName} Icon`,
                      location: 'Feature Grid',
                    });
                  }}
                >
                  <Image src={featureIcon.src} alt={featureName} height={60} width={60} />
                </Link>
              </Styled.FeatureIconWrapper>
              <Styled.FeatureName>
                <Link
                  href={href}
                  onClick={() => {
                    gtm.track('click', {
                      element_type: 'link',
                      element_label: `${featureName} Text`,
                      location: 'Feature Grid',
                    });
                  }}
                >
                  {featureName}
                </Link>
              </Styled.FeatureName>
            </Styled.FeatureItem>
          );
        })}
      </Styled.FeatureGrid>

      <Styled.FeatureGridCTAWrapper>
        <FrontPinkButton
          type="button"
          onClick={() => {
            toggleTrialModal(true);

            gtm.track('click', {
              element_type: 'button',
              element_label: TRIAL_CTA_TEXT,
              location: 'Product Overview Section',
            });
          }}
        >
          {TRIAL_CTA_TEXT}
        </FrontPinkButton>

        <SecondaryDefaultButton
          as="button"
          onClick={() => {
            toggleDemoModal(true);

            gtm.track('click', {
              element_type: 'button',
              element_label: DEMO_CTA_TEXT,
              location: 'Product Overview Section',
            });
          }}
        >
          {DEMO_CTA_TEXT}
        </SecondaryDefaultButton>
      </Styled.FeatureGridCTAWrapper>
    </Styled.SectionContainer>
  );
};

export default ProductOverviewFeatures;
