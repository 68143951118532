import SocialProofCard, { SocialProofCardProps } from '@/components/_atoms/social-proof-cards/SocialProofCard';
import { mediaBreakpointUp } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export interface SocialProofStatCardProps extends SocialProofCardProps {
  logo?: ReactNode;
  value: string | number;
  label: string;
}

const SocialProofStatCard: FC<SocialProofStatCardProps> = (props) => {
  return (
    <SocialProofCard cta={props.cta}>
      {props.logo ? <StatCardLogoWrapper>{props.logo}</StatCardLogoWrapper> : null}

      <CardInner>
        <StatWrapper>
          <StatValue>{props.value}</StatValue>
          <StatLabel>{props.label}</StatLabel>
        </StatWrapper>

        <CardContent>{props.children}</CardContent>
      </CardInner>
    </SocialProofCard>
  );
};

export default SocialProofStatCard;

// Styled Components

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${remCalc(20)};
`;

const StatValue = styled.span`
  ${customTextSize(60, 72)};
  text-align: center;
  letter-spacing: -0.01em;
  background: linear-gradient(90deg, #ff2b73 19.41%, #ff6c35 80.07%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(70, 83)};
  }
`;

const StatLabel = styled.span`
  ${customTextSize(16, 20)};
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  font-weight: ${FONT_WEIGHTS.BOLD};
`;

const CardContent = styled.p`
  ${customTextSize(16, 24)};
  text-align: center;

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(18, 26)};
  }
`;

const StatCardLogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 50%;
  max-height: ${remCalc(40)};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
`;
