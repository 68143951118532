import Heading from '@/components/_atoms/Heading';
import {
  applyBreakpointNegativeMargins,
  BREAKPOINT_PADDING_VAR,
  mediaBreakpointOnly,
  mediaBreakpointUp,
} from '@/styles/layout/utils';
import { remCalc } from '@/styles/typography/utils';
import { FC, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

export interface LogoCarouselProps {
  heading?: string;
  logos: ReactNode[];
}

const LogoCarousel: FC<LogoCarouselProps> = ({ heading, logos }) => (
  <Grid>
    {!!heading ? <StyledHeading level={3}>{heading}</StyledHeading> : null}

    <Carousel>
      {/* Carousel is repeated to ensure a long enough loop of logos to repeat without gap at most screen widths */}
      <CarouselSlide logos={logos} />
      <CarouselSlide logos={logos} />
      <CarouselSlide logos={logos} />
    </Carousel>
  </Grid>
);

const CarouselSlide: FC<Pick<LogoCarouselProps, 'logos'>> = ({ logos }) => {
  return <Slide>{logos.map((logo) => logo)}</Slide>;
};

// Styled Components

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  ${mediaBreakpointUp('tablet')} {
    grid-template-columns: repeat(9, 1fr);
  }

  ${mediaBreakpointUp('desktop')} {
    grid-template-columns: repeat(16, 1fr);
    row-gap: 30px;
  }

  ${mediaBreakpointUp('desktopWide')} {
    grid-template-columns: repeat(18, 1fr);
    row-gap: 60px;
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;

  ${mediaBreakpointUp('tablet')} {
    grid-column: 2 / -2;
  }

  ${mediaBreakpointUp('desktop')} {
    grid-column: 3 / -3;
  }

  ${mediaBreakpointUp('desktopWide')} {
    grid-column: 4 / -4;
  }
`;

const scrollKeyframes = keyframes`
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
`;

interface LogoWrapperProps {
  isTaller?: boolean;
}

const LogoWrapper = styled.div<LogoWrapperProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  :last-of-type {
    margin-right: var(--logo-carousel-gap);
  }

  > img {
    height: calc(var(--logo-carousel-size) * ${(props) => (props.isTaller ? 1.3333 : 1)});
    width: auto;
  }
`;

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--logo-carousel-gap);
  animation: ${scrollKeyframes} 90s linear infinite;
  flex: 0 0 auto;

  @media (prefers-reduced-motion) {
    animation-play-state: paused;
  }
`;

const Carousel = styled.div`
  --logo-carousel-size: ${remCalc(30)};
  --logo-carousel-gap: ${remCalc(15)};

  grid-column: 1 / -1;

  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  user-select: none;

  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 5%,
    black 15%,
    black 85%,
    rgba(0, 0, 0, 0.5) 95%,
    transparent 100%
  );

  :hover,
  :focus-within {
    ${Slide} {
      animation-play-state: paused;
    }
  }

  ${mediaBreakpointOnly('mobile')} {
    ${applyBreakpointNegativeMargins};
    width: calc(100% + (${BREAKPOINT_PADDING_VAR} * 2));
  }

  ${mediaBreakpointUp('tablet')} {
    --logo-carousel-gap: ${remCalc(25)};
  }

  ${mediaBreakpointUp('desktop')} {
    --logo-carousel-size: ${remCalc(35)};
    --logo-carousel-gap: ${remCalc(50)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    --logo-carousel-gap: ${remCalc(60)};
  }
`;

export default LogoCarousel;

export { LogoWrapper };
