import Heading from '@/components/_atoms/Heading';
import { Container } from '@/components/layout/Container';
import { mediaBreakpointUp, paddingY } from '@/styles/layout/utils';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

export interface GradientFooterProps {
  heading: string;
  cta?: ReactNode;
}

const GradientFooter: FC<GradientFooterProps & PropsWithClassName> = (props) => {
  return (
    <Footer className={props.className}>
      <Container>
        <Grid>
          <StyledHeading level={2}>{props.heading}</StyledHeading>
          {props.cta ? <ButtonWrapper>{props.cta}</ButtonWrapper> : null}
        </Grid>
      </Container>
    </Footer>
  );
};

export default GradientFooter;

// Styled Components

const Footer = styled.section`
  position: relative;
  ${paddingY(80)}

  ${mediaBreakpointUp('tablet')} {
    ${paddingY(120)}
  }

  ${mediaBreakpointUp('desktop')} {
    ${paddingY(160)}
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${paddingY(220)}
  }

  &::before {
    content: '';
    width: 100%;
    height: var(--gradient-spread, 200%);
    position: absolute;
    top: calc((var(--gradient-spread, 200%) - 100%) * -1);
    left: 0;
    z-index: -10;
    transform: matrix(1, 0, 0, -1, 0, 0);
    background: var(
      --gradient-background,
      linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #ffffff 100%),
      radial-gradient(50% 65.18% at 100% 100%, #fffadd 0%, rgba(255, 252, 236, 0) 100%),
      radial-gradient(63.5% 72.5% at 0% 100%, #acf8e2 0%, rgba(220, 255, 243, 0) 100%),
      radial-gradient(85.51% 107.61% at 116.48% -16.77%, #b0f1fc 0%, rgba(217, 248, 255, 0) 100%),
      radial-gradient(85.41% 95.63% at -15.24% -17.56%, #ffd4e6 0%, rgba(255, 212, 230, 0) 100%),
      #ffffff
    );
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  margin-bottom: ${remCalc(20)};
  grid-column: 1 / -1;

  ${mediaBreakpointUp('tablet')} {
    ${customTextSize(49, 58)};
    margin-bottom: ${remCalc(30)};
  }

  ${mediaBreakpointUp('desktop')} {
    ${customTextSize(60, 66)};
  }

  ${mediaBreakpointUp('desktopWide')} {
    ${customTextSize(81, 88)};
    grid-column: 2 / -2;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: ${remCalc(20)};

  ${mediaBreakpointUp('tablet')} {
    grid-template-columns: repeat(9, 1fr);
  }
`;

const ButtonWrapper = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  ${mediaBreakpointUp('tablet')} {
    flex-direction: row;
  }
`;
