import Slide from '@/components/pages/home/sections/vectors/slide';
import { FC, useRef } from 'react';
import { applyStrongTag } from '../../shared/utilities/textUtils';
import Styled from './styled.components';

export interface VectorsSectionProps {
  header: {
    headline: string;
    statement: string;
  };
  segments: VectorSegmentProps[];
}

export interface VectorSegmentProps {
  headline: string;
  statement: string;

  video: {
    src: string;
    height: number;
    width: number;
  };
  cta: {
    text: string;
    href: string;
  };
}

const VectorsSection: FC<VectorsSectionProps> = ({ header: { headline, statement }, segments }): JSX.Element => {
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Styled.VectorsSectionContainer>
      <Styled.VectorsSection>
        <Styled.VectorsHeader>
          <Styled.VectorsHeadline>{applyStrongTag(headline)}</Styled.VectorsHeadline>
          <Styled.VectorsStatement>{statement}</Styled.VectorsStatement>
        </Styled.VectorsHeader>
      </Styled.VectorsSection>

      <Styled.SlidesContainer ref={rootRef}>
        {segments.map((segment, index) => (
          <Slide {...segment} rootRef={rootRef} />
        ))}
      </Styled.SlidesContainer>
    </Styled.VectorsSectionContainer>
  );
};

export default VectorsSection;
