import Modal, { StyledModalScrollWrapper } from '@/components/modals/Modal';
import { GlobalContext } from '@/context';
import { NavatticIframe } from '@/lib/navattic';
import { COLORS } from '@/styles/color';
import { hexToRGBA } from '@/styles/color/utils';
import { paddingX } from '@/styles/layout/utils';
import { FONT_WEIGHTS } from '@/styles/typography';
import { customTextSize, remCalc } from '@/styles/typography/utils';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface ProductTourModalProps {
  onClose: () => void;
  version?: 'default' | 'no-demo' | 'no-ctas';
}

const ProductTourModal: FC<ProductTourModalProps> = ({ onClose, version = 'default' }) => {
  const { toggleDemoModal, toggleTrialModal } = useContext(GlobalContext);
  const ref = useRef<HTMLDivElement>(null);
  const [showFullscreenNotice, setShowFullscreenNotice] = useState(false);

  let flowId = 'cluvku4xs00000fl64c65dxdi';

  if (version === 'no-demo') {
    flowId = 'clvzoh195000109jqeidy9e1e';
  } else if (version === 'no-ctas') {
    flowId = 'clx2cjmkg000a09l714ighwhc';
  }

  useEffect(() => {
    if (!ref.current) return;

    const scrollWrapper = ref.current.querySelector('[data-modal-part="scroll-wrapper"]');

    if (!scrollWrapper) return;

    const handler = () => {
      setShowFullscreenNotice(scrollWrapper.scrollHeight > scrollWrapper.clientHeight && document.fullscreenEnabled);
    };

    const onFullScreenChange = () => {
      if (!document.fullscreenElement) {
        onClose();
      }
    };

    window.addEventListener('resize', handler);
    scrollWrapper.addEventListener('fullscreenchange', onFullScreenChange);

    handler();

    return () => {
      window.removeEventListener('resize', handler);
      scrollWrapper.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, [ref.current]);

  return (
    <div ref={ref}>
      <TourModal name="Navattic Tour" onClose={onClose} whiteCloseButton id="product-tour-modal">
        <StyledNavatticIframe
          src={`https://capture.navattic.com/${flowId}`}
          onEvent={(data) => {
            if (data.type === 'NAVIGATE' && data.url) {
              if (data.url.includes('#signup')) {
                toggleTrialModal(true);
                void document.exitFullscreen();
              } else if (data.url.includes('#demo')) {
                toggleDemoModal(true);
                void document.exitFullscreen();
              }
            }
          }}
        />

        {showFullscreenNotice && (
          <FullScreenNotice>
            <div
              onClick={() => {
                ref.current?.querySelector('[data-navattic-wrapper]')?.requestFullscreen({
                  navigationUI: 'hide',
                });
              }}
            >
              For the most optimal demo experience, click here to go fullscreen
            </div>
          </FullScreenNotice>
        )}
      </TourModal>
    </div>
  );
};

export default ProductTourModal;

const TourModal = styled(Modal)`
  --modal-padding: 0;
  --modal-button-top: ${remCalc(-40)};
  --modal-button-right: ${remCalc(10)};
  --modal-max-width: ${remCalc(1800)};

  ${StyledModalScrollWrapper} {
    padding-top: 50px;
    padding-bottom: 0;
    ${paddingX(0)};
  }
`;

const StyledNavatticIframe = styled(NavatticIframe)`
  aspect-ratio: 16 / 10;
  width: 100%;
  height: auto;

  &:fullscreen {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 55.94%, #ffffff 100%),
      radial-gradient(50% 65.18% at 100% 100%, #fffadd 0%, rgba(255, 252, 236, 0) 100%),
      radial-gradient(63.5% 72.5% at 0% 100%, #acf8e2 0%, rgba(220, 255, 243, 0) 100%),
      radial-gradient(85.51% 107.61% at 116.48% -16.77%, #b0f1fc 0%, rgba(217, 248, 255, 0) 100%),
      radial-gradient(85.41% 95.63% at -15.24% -17.56%, #ffd4e6 0%, rgba(255, 212, 230, 0) 100%) #ffffff;

    iframe {
      aspect-ratio: 16 / 10;
      height: calc(100% - 2rem);
      width: auto;
      margin: 1rem auto;
      border-radius: 10px;
      box-shadow: 0 0 30px 0 ${hexToRGBA(COLORS.BLACK, 0.15)};
    }
  }
`;

const FullScreenNotice = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 65px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background: ${COLORS.BLUE.LOWLIGHT};
    color: ${COLORS.WHITE};
    font-weight: ${FONT_WEIGHTS.SEMIBOLD};
    padding: 8px 12px;
    z-index: 100;
    border-radius: 5px;
    ${customTextSize(14, 16)};
    cursor: pointer;
  }
`;
